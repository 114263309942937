import React from "react";
import Box from "@mui/material/Box";
import { StaticImage } from "gatsby-plugin-image";

const HeroImage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <StaticImage
        src="../../../../assets/images/slideshow/screenshot-01.png"
        style={{ borderRadius: "20px", border: `1px solid #808080` }}
        alt="Magic Audio Logo"
        width={600}
      />
    </Box>
  );
};

export default HeroImage;
