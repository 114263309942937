import React from 'react';
import { Box, Container } from '@mui/material';

const HeroContainer = (props) => {
  return (
    <Container>
      <Box
        // sx={{
        //   backgroundColor: `rgb(29,30,31,1)`,
        //   background:
        //     'radial-gradient(circle, rgba(29,30,31,1) 15%, rgba(39,40,41,1) 55%, rgba(29,30,31,1) 85%)',
        // }}
        pb={5}
      >
        {props.children}
      </Box>
    </Container>
  );
};

export default HeroContainer;
