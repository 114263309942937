import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Seo from '../../../app/Seo';
import DefaultLayout from '../../layouts/DefaultLayout';

import HeroImage from './HeroImage';
import HeroContainer from './HeroContainer';

import heroTranslations from '../../../locales-js/en/hero';
import GetTheExtension from "../../components/GetTheExtension";
import BackgroundParticles from "../../components/BackgroundParticles";
import ListenToRealTimeDemo from "../../components/ListenToRealTimeDemo";

export default function IndexPage(props) {
  return (
    <>
      <Seo title='Home' />
      <DefaultLayout>
        <BackgroundParticles/>
        <Container>
          <HeroContainer>
            <Typography
              variant='h2'
              component='h1'
              align='center'
              gutterBottom={false}
              pt={3.75}
              pb={7.5}
              sx={{
                fontWeight: `normal`
              }}
            >
              {heroTranslations.heroTitle}
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <HeroImage />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant='h4'
                  component='h2'
                  align='center'
                  gutterBottom={true}
                  mb={0}
                >
                  <strong>{heroTranslations.heroSubtitle1_1}</strong> <br/> {heroTranslations.heroSubtitle1_2} <br/> {heroTranslations.heroSubtitle1_3}
                </Typography>
                <GetTheExtension />
                <ListenToRealTimeDemo/>
                {/* <Typography variant='h6' align='left' gutterBottom={true}>
                  {translation(`heroSubtitle2`)}
                </Typography> */}
              </Grid>
            </Grid>
          </HeroContainer>
        </Container>
      </DefaultLayout>
    </>
  );
}
