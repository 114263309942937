import React from 'react';
import { navigate } from 'gatsby';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HearingIcon from '@mui/icons-material/Hearing';
export default function ListenToRealTimeDemo(props) {
  const onHandleListenToRealTimeDemo = () => {
    navigate('/web-extension-addon/real-time-demo');
  };

  return (
    <>
      {/* <Typography variant='h5' align='center' gutterBottom={true}>
        {translation(`yourCurrentBrowser`)}
      </Typography> */}

      <Box textAlign='center' mt={3} mb={2}>
        <Button
          color='primary'
          variant='text'
          size='medium'
          startIcon={<HearingIcon />}
          onClick={onHandleListenToRealTimeDemo}
        >
          Listen to Real-Time Demo
        </Button>
      </Box>
    </>
  );
}
