const heroTranslations = {
  heroTitle: "More than typical stereo",
  heroTitleAlt0: "The pleasure for the sense of hearing.",
  heroTitleAlt1: "Enjoy a wide soundstage with clarity.",
  heroTitleAlt2: "Stunning sound from the web browser.",
  heroTitleAlt3: "Give pleasure to your ears",
  heroTitleAlt4: "Please your ears",
  heroTitleAlt5: "Stunning sound from your web browser",
  heroTitleAlt6: "Enjoy Stunning web sound",
  heroTitleAlt7: "Stunning sound from your browser",
  heroTitleAlt8: "Better Sound from Your Browser",
  heroTitleAlt9: "Stunning sound from your browser",
  heroTitleAlt10: "Stunning SOUND @ BROWSER",
  heroSubtitle1_1:
      "Virtual Surround",
  heroSubtitle1_2:
      "for music and movies",
  heroSubtitle1_3:
      "in the web browser",
  heroSubtitleAlt0:
      "Enjoy an immersive listening experience for music and movies in your web browser",
  heroSubtitle1Alt1: "Enjoy a wide soundstage with clarity.",
  heroSubtitle1Alt2: "Enjoy wide soundstage with virtual surround & more.",
  heroSubtitleAlt3:
      "Enjoy a wide soundstage for music and movies played within your web browser.",
  heroSubtitle1Alt4:
      "Enjoy a wide soundstage and clarity for music and movies in your web browser.",
  heroSubtitleAlt5:
      "Enjoy an immersive listening experience for music and movies played within your web browser",
  heroSubtitle2:
      "Virtual Surround, Equalizer, Volume Control, Dynamics Compressor & more.",
};

export default heroTranslations;
