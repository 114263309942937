import React from 'react';
import { navigate } from 'gatsby';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExtensionIcon from '@mui/icons-material/Extension';

export default function GetTheExtension(props) {
  const onHandleGetExtension = () => {
    navigate('/web-extension-addon');
  };

  return (
    <>
      {/* <Typography variant='h5' align='center' gutterBottom={true}>
        {translation(`yourCurrentBrowser`)}
      </Typography> */}

      <Box textAlign='center'
           mt={3}
           mb={2}
      >
        <Button
          color='secondary'
          variant='contained'
          size='large'
          startIcon={<ExtensionIcon />}
          sx={{
            padding: `1.125rem`
          }}
          onClick={onHandleGetExtension}
        >
          Get the Web Extension / Addon
        </Button>
      </Box>
    </>
  );
}
